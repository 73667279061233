import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import React, { useEffect, useState } from 'react'
import ProductService from '../../../services/products'
import SessionService from '../../../services/session'
import CardArticleItem from '../cardArticleItem/cardArticleItem'
import ArticleService from '../../../services/articles'
import CardProduct from '../cardProduct/cardProduct'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './productsSections.scss'
import './sample.scss'
import GuidelineCard from '../guideline-card/guideline-card'
import LeafletCard from '../leaflet-card/leaflet-card'
import  {pushGtagEvent}  from '../../../../shared/utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'


const optionsMuestra = {
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node, children) => (
			<div
				className='f-muestra'
				dangerouslySetInnerHTML={{ __html: children }}
			/>
		),
	},
}

const ProductsSections = props => {
	const { isFreeSamples } = props
	const ORDEN_ARTICULO = 'Artículo'
	const ORDEN_PRODUCTO = 'Producto'
	const ORDEN_ELEMENTO_DESTACADO = 'Elemento Destacado'
	const newSlug = props.slug.split('/')[0]
	const [listLikes, setlistLikes] = useState(null)
	const [localLikes, setlocalLikes] = useState([])
	const [listArticlesLikes, setlistArticlesLikes] = useState(null)
	const [localArticlesLikes, setlocalArticlesLikes] = useState([])

	useEffect(() => {
		handleLoadLikes()
		handleLoadArticlesLikes()
		setlocalLikes(
			localStorage.getItem('LOCAL_LIKES_PRODUCTS') !== null
				? JSON.parse(localStorage.getItem('LOCAL_LIKES_PRODUCTS'))
				: []
		)
		setlocalArticlesLikes(
			localStorage.getItem('LOCAL_LIKES_ARTICLES') !== null
				? JSON.parse(localStorage.getItem('LOCAL_LIKES_ARTICLES'))
				: []
		)
	}, [])

	const isLiked = (idProduct, list) => {
		let i
		for (i = 0; i < list.length; i++) {
			if (list[i] === idProduct) {
				return true
			}
		}

		return false
	}

	const getProductAtr = (idProduct, list) => {
		let i
		for (i = 0; i < list.length; i++) {
			if (list[i].idCMS === idProduct) {
				return list[i]
			}
		}

		return false
	}

	const handleLoadLikes = () => {
		if (
			!(
				localStorage.getItem('tokenData') || sessionStorage.getItem('tokenData')
			)
		) {
			// Verify if is user logged
			ProductService.getAnomLikes()
				.then(res => {
					setlistLikes(res.data)
				})
				.catch(e => console.log(e))
		} else {
			localStorage.removeItem('LOCAL_LIKES_PRODUCTS')
			const config = {
				headers: {
					Authorization: SessionService.getIdTokenData(),
				},
			}

			ProductService.getAuthLikes(config)
				.then(res => {
					for (let index = 0; index < res.data.length; index++) {
						if (res.data[index].userLike) {
							if (localStorage.getItem('LOCAL_LIKES_PRODUCTS') !== null) {
								const copyLocalLikes = JSON.parse(
									localStorage.getItem('LOCAL_LIKES_PRODUCTS')
								)
								localStorage.setItem(
									'LOCAL_LIKES_PRODUCTS',
									JSON.stringify(copyLocalLikes.concat(res.data[index].idCMS))
								)
								setlocalLikes(copyLocalLikes.concat(res.data[index].idCMS))
							} else {
								localStorage.setItem(
									'LOCAL_LIKES_PRODUCTS',
									JSON.stringify([res.data[index].idCMS])
								)
								setlocalLikes([res.data[index].idCMS])
							}
						}
					}
					setlistLikes(res.data)
				})
				.catch(e => console.log(e))
		}
	}

	const handleLoadArticlesLikes = () => {
		if (
			!(
				localStorage.getItem('tokenData') || sessionStorage.getItem('tokenData')
			)
		) {
			// Verify if is user logged
			ArticleService.getAnomLikes()
				.then(res => {
					setlistArticlesLikes(res.data)
				})
				.catch(e => console.log(e))
		} else {
			localStorage.removeItem('LOCAL_LIKES_ARTICLES')

			const config = {
				headers: {
					Authorization: SessionService.getIdTokenData(),
				},
			}

			ArticleService.getAuthLikes(config)
				.then(res => {
					for (let index = 0; index < res.data.length; index++) {
						if (res.data[index].userLike) {
							if (localStorage.getItem('LOCAL_LIKES_ARTICLES') !== null) {
								const copyLocalLikes = JSON.parse(
									localStorage.getItem('LOCAL_LIKES_ARTICLES')
								)
								localStorage.setItem(
									'LOCAL_LIKES_ARTICLES',
									JSON.stringify(copyLocalLikes.concat(res.data[index].idCMS))
								)
								setlocalArticlesLikes(
									copyLocalLikes.concat(res.data[index].idCMS)
								)
							} else {
								localStorage.setItem(
									'LOCAL_LIKES_ARTICLES',
									JSON.stringify([res.data[index].idCMS])
								)
								setlocalArticlesLikes([res.data[index].idCMS])
							}
						}
					}
					setlistArticlesLikes(res.data)
				})
				.catch(e => console.log(e))
		}
	}

	
	const userInfo=dataLayerUserInfo()
	const getAllArticules = articules =>
		(articules || []).map((articulo, index) => {
			return (
				<CardArticleItem
					articulo={articulo}
					key={index}
					idArticle={articulo.id}
					isMobile={props.isMobile}
					likesInfo={
						listArticlesLikes !== null &&
						getProductAtr(articulo.id, listArticlesLikes)
					}
					setlistLikes={setlistArticlesLikes}
					setlocalLikes={setlocalArticlesLikes}
					isLocalLiked={isLiked(articulo.id, localArticlesLikes)}
				/>
			)
		})
		const itemListViewGtag={
			event:'view_item_list',
				params:{	
					items: [],
					item_list_name:
						isFreeSamples ? props.productos[0]?.producto?.linea? props.productos[0]?.producto?.linea[0]?.nombreDeEtiqueta: '' || '' :
						props.productos[0]?.linea? props.productos[0]?.linea[0]?.nombreDeEtiqueta: '' || '',
					flow_type: isFreeSamples?'muestra gratis': 'compra' ,
					user_data: userInfo.user_data? userInfo.user_data :'',
					log_status: userInfo.log_status|| '',
				}
		}
		const params=(prod, index2)=>{			
			const par={
				event:'select_item',
				params:{	
					items: [{
						item_id: prod.codigoDeProducto,
						item_name: prod.nombreProducto,
						index: index2,
						discount: 0,
						item_list_name: prod.categorias[0].titulo,
						item_list_id: prod.id,
						affiliation: 'Tena Online',
						item_category: prod.categorias[0].titulo,
						item_brand: 'Tena',
						item_variant: prod.linea? prod.linea[0].nombreDeEtiqueta : prod.categorias[0].titulo,
						price: 0,
                        currency: process.env.LOCALE,
                        quantity: 1,
						
					}],
					item_list_name: prod.linea? prod.linea[0]?.nombreDeEtiqueta : undefined,
					item_list_id: prod.id,
					drip_degree: prod.tipoDeIncontinencia? prod.tipoDeIncontinencia[0].nombreDeEtiqueta: '',
					user_data: userInfo.user_data? userInfo.user_data :'',
					log_status: userInfo.log_status|| '',
					flow_type: isFreeSamples?'muestra gratis': 'compra' ,
				}}
		return par
		}
	const getAllProducts = products =>
		(products || []).map((producto, index) => {
			let product = isFreeSamples ? producto.producto : producto
			if (props.tienePautaClaves && !props.isMobile && index === 2) {
				return (
					<>
						<CardProduct dataLayerbody={params(product, index)}
							isFreeSamples={isFreeSamples}
							actionCard={props.actionSection}
							modeAction={props.modeAction}
							noHeight={props.noHeight}
							noFooter={props.noFooter}
							slug={props.slug}
							// slug={producto.categorias[0].slug}
							producto={product}
							key={index}
							isSent={verifySent(product.codigoDeProducto, product.talla)}
							samples={
								props.modeAction
									? props.samples.filter(sample => [product.id, product.codigoDeProducto].includes(sample.id))
									: []
							}
							samplesSent={props.samplesSent}
							idProduct={product.codigoDeProducto}
							likesInfo={
								listLikes !== null &&
								getProductAtr(product.codigoDeProducto, listLikes)
							}
							setlistLikes={setlistLikes}
							setlocalLikes={setlocalLikes}
							isLocalLiked={isLiked(product.codigoDeProducto, localLikes)}
						/>
						<GuidelineCard guideline={props.pauta} />
					</>
				)
			}
			if (props.tienePautaClaves && props.isMobile && index === 0) {
				return (
					<>
						<CardProduct dataLayerbody={params(producto, index)}
							isFreeSamples={isFreeSamples}
							actionCard={props.actionSection}
							modeAction={props.modeAction}
							noHeight={props.noHeight}
							noFooter={props.noFooter}
							slug={props.slug}
							// slug={producto.categorias[0].slug}
							producto={producto}
							key={index}
							isSent={verifySent(producto.codigoDeProducto, producto.talla)}
							samples={
								props.modeAction
									? props.samples.filter(sample => [producto.id, producto.codigoDeProducto].includes(sample.id))
									: []
							}
							samplesSent={props.samplesSent}
							idProduct={producto.codigoDeProducto}
							likesInfo={
								listLikes !== null &&
								getProductAtr(producto.codigoDeProducto, listLikes)
							}
							setlistLikes={setlistLikes}
							setlocalLikes={setlocalLikes}
							isLocalLiked={isLiked(producto.codigoDeProducto, localLikes)}
						/>
						<GuidelineCard guideline={props.pauta} />
					</>
				)
			}
			itemListViewGtag.params.items.push(
				{
					item_id: product.codigoDeProducto,
					item_name: product.nombreProducto,
					index: index,
					item_list_name: product.categorias[0].titulo,
					item_list_id: product.id,
					affiliation: 'Tena Online',
					item_category: product.categorias[0].titulo,
					item_brand: 'Tena',
					item_variant: product.linea? product.linea[0].nombreDeEtiqueta : product.categorias[0].titulo,
					currency:process.env.LOCALE,
					drip_degree: product.tipoDeIncontinencia? product.tipoDeIncontinencia[0].nombreDeEtiqueta: '',
				})
			return (
				<CardProduct dataLayerbody={params(product, index)}
					isFreeSamples={isFreeSamples}
					actionCard={props.actionSection}
					modeAction={props.modeAction}
					noHeight={props.noHeight}
					noFooter={props.noFooter}
					slug={props.slug}
					// slug={producto.categorias[0].slug}
					producto={product}
					key={index}
					isSent={verifySent(product.codigoDeProducto, product.talla)}
					samples={
						props.modeAction
							? props.samples.filter(sample => [product.id, product.codigoDeProducto].includes(sample.id))
							: []
					}
					samplesSent={props.samplesSent}
					idProduct={product.codigoDeProducto}
					likesInfo={
						listLikes !== null &&
						getProductAtr(product.codigoDeProducto, listLikes)
					}
					setlistLikes={setlistLikes}
					setlocalLikes={setlocalLikes}
					isLocalLiked={isLiked(product.codigoDeProducto, localLikes)}
				/>
			)
		})
	useEffect(() => {
	  pushGtagEvent(itemListViewGtag)
	}, [])
	
	const verifySent = (idProdcut, sizes) => {
		if (sizes) {
			const filter =
				props.samplesSent &&
				props.samplesSent.filter(
					sample => sizes.includes(sample.size) && idProdcut === sample.id
				)
			return filter && filter.length === sizes.length
		}
		return !!(
			props.samplesSent &&
			props.samplesSent.find(sample => idProdcut === sample.id)
		)
	}

	return (
		<section
			className={`${
				props.isCurved
					? 'f-products-sections f-products-sections--curved'
					: 'f-products-sections'
			}`}
		>
			<div className='f-products-sections-container'>
				{!props.title && !props.slug.includes('productos') && (
					<h2
						className={`f-title-product-section f-title-product-section-${newSlug} f-${newSlug}`}
					>
						{props.titleInteres
							? props.titleInteres
							: 'PRODUCTOS DISEÑADOS PARA TI'}
					</h2>
				)}
				{props.title && props.titleType && props.titleType === 'products' ? (
					<h2
						className={`f-title-product-section f-${newSlug} ${
							props.noCenter ? 'nocenter' : ''
						}`}
					>
						{' '}
						{props.titleInteres
							? props.titleInteres
							: 'PRODUCTOS DISEÑADOS PARA TI'}
					</h2>
				) : props.titleType === 'article' ? (
					<div className='f-head-title'>
						<h2
							className={`f-title-product-section f-article ${
								props.noCenter ? 'nocenter' : ''
							}`}
						>
							{' '}
							{props.title}
						</h2>
						<a className='f-title-link' href='/articulos/'>
							VER TODOS LOS ARTÍCULOS
						</a>
					</div>
				) : (
					<></>
				)}
				<div
					className={`f-cards-container ${props.noCenter ? 'nocenter' : ''}`}
				>
					{props.orden === ORDEN_PRODUCTO && (
						<>
							{getAllArticules(props.articulos)}
							{getAllProducts(props.productos)}
						</>
					)}
					{props.orden === ORDEN_ARTICULO && (
						<>
							{getAllProducts(props.productos)}
							{getAllArticules(props.articulos)}
						</>
					)}
					{(!props.orden || props.orden === ORDEN_ELEMENTO_DESTACADO) && (
						<>
							{getAllArticules(props.articulos)}
							{getAllProducts(props.productos)}
						</>
					)}
					{props.destacados &&
						props.destacados.elementosHtml &&
						documentToReactComponents(
							props.destacados.elementosHtml.json,
							optionsMuestra
						)}
					{/* {props.showCardPointSale && <CardPointSale />} */}
					{props.showCardRecommender && (
						<GuidelineCard guideline={props.guideline} />
					)}
				</div>
				{props.showLeafletCard &&
					(props.isLoggedUser ? null : (
						<LeafletCard
							dataPolicy={props.dataPolicy}
							isMobile={props.isMobile}
							leafletContent={props.leafletContent}
						/>
					))}
			</div>
		</section>
	)
}

export default ProductsSections
